export const categories = [
  { title: 'Weight Loss', urlPath: 'weight-loss', description: "Achieving and maintaining a health weight is important for overall health and can reduce the risk of numerous chronic conditions, including heart disease, diabetes, hypertension, and certain cancers.", 
    iconPath: './images/icons/weight-loss.png', 
    image: 'photos/urgent2.jpg', 
    treatments: [
      'Start Your Weight Loss Journey'
    ]
  },
  { title: 'Urgent Care', description: "Navigate through life's unexpected health concerns with ease. Our Urgent Care provides swift, expert attention for acute medical issues, ensuring you're back on your feet in no time.", iconPath: './images/icons/urgent-care.png', 
    urlPath: 'urgent-care',
    image: 'photos/urgent2.jpg',
    treatments: [
      'General Consult',
      'Allergies',
      'Headache',
      'Medication Refill',
      'Migraine - Headache',
      'Rash',
      'Shortness of Breath',
      'Sinus Infection',
      'Sore Throat',
      'Urinary Tract Infection',
      'Abdominal Pain',
      'Acute Cough',
      'Ankle Injury',
      'Ankle Pain',
      'Back Pain',
      'COVID-19',
      'Cold and Flu',
      'Earache',
      'Arthritis',
      'Asthma',
      'Gout',
      'Pinkeye'
    ]
  },
  { title: 'Women\'s Health', description: "Empowering women through every stage of life with care and support. Explore our Women's Health services for personalized solutions in a nurturing environment.", iconPath: './images/icons/womens-health.png',
    urlPath: 'womens-health',
    image: 'photos/woman2.jpg',
    treatments: [
      'General Consult',
      'Birth Control',      
      'Genital Warts',
      'Overactive Bladder',
      'Urinary Tract Infection',
      'Yeast Infection',
      'Migraine - Headache',
      'Anti-Aging',
      'Female Hair Loss'
    ]
  },
  { title: 'Men\'s Health', description: "Dedicated to addressing the unique health challenges men face, our Men's Health services combine expert care with tailored advice, promoting optimal wellness at any age.", iconPath: './images/icons/mens-health.png',
    urlPath: 'mens-health',
    image: 'photos/man2.jpg',
    treatments: [
      'General Consult',
      'Erectile Dysfunction',      
      'Genital Warts',
      'Hair Loss',
      'Urinary Tract Infection',
      'Anti-Aging',
      'Gout'
    ] 
  },
  { title: 'Pediatric Health', description: "Our Pediatric Health services provide compassionate care in a child-friendly setting, making health a joyful journey.", iconPath: './images/icons/pediatric-health.png', 
    urlPath: 'pediatric-health',
    image: 'photos/pediatric2.jpg',
    treatments: [
      'General Consult - Pediatric',
      'Abdominal Pain - Pediatric',
      'Acute Cough - Pediatric',
      'Diarrhea - Pediatric',
      'Earache - Pediatric',      
      'Rash - Pediatric',
      'Sore Throat - Pediatric',
      'Pinkeye - Pediatric'
    ] 
  },
  { title: 'Allergies', urlPath: 'allergies', description: "Breathe easier and live comfortably by managing your allergies effectively. Our specialized care helps identify triggers and tailor treatments, paving the way for days with reduced symptoms.", iconPath: './images/icons/allergy.png',
    image: 'photos/allergies2.jpg',
    treatments: ['General Consult','Allergies'] 
  },
  { title: 'Dermatology', urlPath: 'dermatology', description: "Let our Dermatology treatments make life easier by receiving virtual care for simple skin conditions.  Refill a previously prescribed medication or get a new care plan.", iconPath: './images/icons/dermatology.png', 
    image: 'photos/dermatology2.jpg',
    treatments: ['General Consult','Acne','Rash','Eczema','Anti-Aging']
  },
  { title: 'Optometry', urlPath: 'optometry', description: "Optometry services encompass a range of eye care solutions tailored to individual needs. When it comes to conditions like dry eye and pink eye, optometrists play a crucial role in diagnosis, treatment, and management.", iconPath: './images/icons/optometry.png', 
    image: 'photos/optometry2.jpg',
    treatments: ['General Consult','Dry Eye','Pinkeye'] },
  { title: 'Mental Wellness', urlPath: 'mental-wellness', description: "Nourish your mind and spirit with our Mental Wellness services, offering a sanctuary for healing and strength. Embark on a journey to mental well-being with our compassionate support.", iconPath: './images/icons/mental-wellness.png', 
    image: 'photos/mental2.jpg',
    treatments: ['General Consult'] },
  { title: 'Medication Refill', urlPath: 'medication-refill', description: "Easily renew your prescriptions with our Medication Refill service. Experience hassle-free access to your essential medications, ensuring your health regimen remains uninterrupted and optimized for your well-being.", iconPath: './images/icons/medication-refill.png', 
    image: 'photos/refill2.jpg', },
  { title: 'General Consult', urlPath: 'general-consult', description: "Begin your journey to better health with a General Consult. Our expert team is ready to address a wide range of health concerns, offering personalized advice and solutions tailored to your unique health needs.", iconPath: './images/icons/general-consult.png', 
    image: 'photos/general2.jpg', },
  { title: 'Chronic Conditions', urlPath: 'chronic-conditions', description: "Living with a chronic condition can be challenging, but you're not alone. Our dedicated services provide continuous support and management strategies, empowering you to lead a fulfilling life.", 
    image: 'photos/chronic2.jpg', iconPath: './images/icons/chronic-conditions.png', 
    treatments: ['General Consult','Chronic Lower Back Pain','Chronic Cough'] },

];

